import store from '../store/index'

export async function fetchService(data) {
  const { endpoint = '', method = 'GET', payload = {}, apitype } = data
  const company = store.state.user.company
  const user = store.state.user.user
  const api = store.state.api[apitype]
  try {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    if (apitype === 'ask') headers.append('Authorization', `Bearer ${btoa(`${company}/${user}:`)}`)
    const config = {
      method,
      headers
    }
    if (method !== 'GET') {
      if (!data.sendfile) config.body = JSON.stringify(payload)
      else config.body = payload
    }
    const response = await fetch(
      `${api}${endpoint}`, config
    )
    if (response.ok) {
      const data = await response.json();
      return data
    } else {
      return response
    }
  } catch (error) {
    console.error(error)
    return error
  }
}