<template>
  <div class="main-div">
    <div class="info-div">
      <div class="label-div">
        <div class="label-text">{{ label }}</div>
        <div v-if="required" class="required-text">*</div>
      </div>
      <div v-if="isRequired" class="required-text" >Este campo es requerido</div>
      <div v-if="!isValidated" class="required-text" >No es un formato válido</div>
      <div v-if="!realRut" class="required-text" >No es un RUT válido</div>
    </div>
    <div :class="{'input-area': isTextArea, 'input-disabled': disabled}" class="input-div">
      <input :id="id" v-if="type==='date'" type="date"  v-model="value" :placeholder="placeholder" :disabled="disabled" @input="emitValue" @focusout="focus = true">
      <select :id="id" v-else-if="type==='select'" type="select" v-model="value" :placeholder="placeholder" :disabled="disabled" @change="emitValue" @focusout="focus = true">
        <option v-for="option in options" :key="option?.id || option" :value="option?.id || option">{{ option?.nombre || option?.nombres || option }}</option>
      </select>
      <textarea :id="id" v-else-if="type==='textarea'" v-model="value" :placeholder="placeholder" :disabled="disabled" @change="emitValue" @focusout="focus = true"></textarea>
      <input :id="id" v-else type="text" v-model="value" :placeholder="placeholder" :disabled="disabled" @input="emitValue" @focusout="focus = true">
      <div v-if="value && type !== 'select'" class="delete-div" @click="deleteValue">
        <span class="material-symbols-outlined">
          close
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IcbInput',
  data() {
    return {
      value: '',
      focus: false,
      realRut: true
    }
  },
  props: {
    clean: Boolean,
    disabled: Boolean,
    label: String,
    id: String,
    options: Array,
    placeholder: String,
    required: Boolean,
    type: String,
    validation: String
  },
  computed: {
    isTextArea() {
      return this.type === 'textarea'
    },
    isRequired() {
      return this.required && !this.value && this.focus
    },
    isValidated() {
      if (!this.value) return true
      switch (this.validation) {
        case 'phone':
          return new RegExp(/^(?=.{8,20}$)([+]?[(]?[0-9]{2,3}[)]?[0-9]{6,17}$)/).test(this.value)
        case 'email':
          return new RegExp(/\S+[@]\S+[.]\S+/).test(this.value)
        case 'rut':
          // return new RegExp(/[0-9]{2}[.][0-9]{3}[.][0-9]{3}[-][0-9]{1}/).test(this.value)
          return this.validateRut()
        case 'time':
          return new RegExp(/[00-23]{1}[:][00-59]{1}/).test(this.value)
        default:
          return true
      }
    },
  },
  methods: {
    validateRut() {
      this.realRut = true
      const rutRegex = /^0*(\d{1,3}(\.?\d{3})*)-([\dkK])$/
      if (!this.value.match(rutRegex)) return false
      const rutParts = this.value.split('-')
      rutParts[0] = rutParts[0].split('').reverse().join('');
      const intRut = rutParts[0].replace(/\./g, '')
      let factor = 2
      let suma = 0
      for (const int of intRut) {
        suma += parseInt(int) * factor
        factor = factor === 7 ? 2 : factor + 1
      }
      let dvEsperado = 11 - (suma % 11)
      if (dvEsperado === 11) {
        dvEsperado = 0
      } else if (dvEsperado === 10) {
        dvEsperado = 'K'
      }
      if (rutParts[1].toUpperCase() !== dvEsperado.toString()) {
        this.realRut = false
      } else {
        this.realRut = true
      }
      return true
    },
    deleteValue() {
      this.value = ''
      this.realRut = true
      this.emitValue()
    },
    emitValue() {
      if(!this.isValidated) return false
      const dataEmited = {
          id: this.id,
          value: this.value,
        }
      if (this.id === 'product' || this.id === 'type') {
        dataEmited.name = this.options.filter(option => option.id === this.value)[0].nombre
      } else if (this.id === 'subtype') {
        dataEmited.name = this.options.filter(option => option.id === this.value)[0].nombres
      }
      this.$emit('emitvalue', dataEmited)
    }
  },
  watch: {
    clean(newValue) {
      if (newValue) {
        this.value = ''
        this.focus = false
      }
    }
  }
}
</script>

<style scoped>
@media (min-width: 461px) {
  .main-div {
    min-width: 400px;
  }
  .info-div {
    align-items: center;
  }
  .required-text {
    font-size: 12px;
  }
}

@media (max-width: 460px) {
  .main-div {
    min-width: 250px;
  }
  .info-div {
    flex-direction: column;
    align-items: start;
    font-size: small;
  }
  .required-text {
    font-size: 10px;
  }
}
.main-div {
  display: flex;
  flex-direction: column;
  min-height: 70px;
  padding: 5px;
}
.info-div {
  display: flex;
  justify-content: space-between;
  height: 40%;
  max-height: 30px;
}
.label-div {
  display: flex;
}
.required-text {
  color: red;
}
.input-area {
  height: 150px !important;
}
.input-div {
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  padding: 5px;
}
input:disabled, .input-disabled {
  background: #d2d2d2;
}
input, select, textarea {
  font-family: Montserrat;
  border: none;
  outline: none;
  height: 90%;
  width: 100%;
  resize: none;
}
input:focus, select:focus {
  outline: none;
}
.delete-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5%;
  color: #7e7e7e;
  cursor: pointer;
}
</style>