<template>
  <div class="form-div">
    <div v-show="notification || sendingForm" class="notification-blur"></div>
    <div v-show="notification || sendingForm" class="notification-div">
      <span v-if="sendingForm" class="material-symbols-outlined load-icon">sync</span>
      <template v-if="notification">
        <div class="close-modal">
          <span class="material-symbols-outlined" style="cursor: pointer;" @click="closeModal()">close</span>
        </div>
        <div class="notification-icon-div">
          <span v-if="notificationError" class="material-symbols-outlined error-icon">cancel</span>
          <span v-else class="material-symbols-outlined check-icon">check_circle</span>
        </div>
        <div class="notification-message-div">
          <span class="notification-message">{{ notification }}</span>
        </div>
      </template>
    </div>
    <p>Datos del contacto</p>
    <div class="contact-div">
      <icb-input id="rut" label="RUT" placeholder="ej: 12.345.678-9" validation="rut" required @emitvalue="setValue"
        :clean="clean" />
      <icb-input id="name" label="Nombre" required @emitvalue="setValue" :clean="clean" />
      <icb-input id="lastname" label="Apellido" required @emitvalue="setValue" :clean="clean" />
      <icb-input id="email" label="Correo electrónico" placeholder="ej: example@mail.com" validation="email" required
        @emitvalue="setValue" :clean="clean" />
      <icb-input id="phone" label="Télefono" placeholder="ej: +1234567890" validation="phone" required
        @emitvalue="setValue" :clean="clean" />
      <icb-input id="region" label="Región" required @emitvalue="setValue" :clean="clean" type="select" :options="regionList" />
      <icb-input id="commune" label="Comuna" required @emitvalue="setValue" :clean="clean" type="select" :options="communeList"/>
      <icb-input id="address" label="Dirección" required @emitvalue="setValue" :clean="clean" />
    </div>
    <p>Datos del caso</p>
    <div class="case-div">
      <icb-input id="product" label="Tipo de caso" type="select" :options="products" :disabled="!products.length" required
        @emitvalue="setValue" :clean="clean" />
      <!-- <icb-input id="type" label="Motivo" type="select" :options="types" :disabled="!types.length" required
        @emitvalue="setValue" :clean="clean" />
      <icb-input id="subtype" label="Submotivo" type="select" :options="subtypes" :disabled="!subtypes.length" required
        @emitvalue="setValue" :clean="clean" /> -->
      <icb-input id="subject" label="Asunto" required @emitvalue="setValue" :clean="clean" />
      <icb-input id="order" label="Nro. de pedido" placeholder="ej: 1363590517114-01" @emitvalue="setValue" :clean="clean" :disabled="data.product !== 29"/>
      <icb-input id="description" label="Descripción del caso" type="textarea" required @emitvalue="setValue"
        :clean="clean" />
    </div>
    <div class="sub-case-div" v-if="data.product === 27">
      <div class="sub-case-header">
      <!-- <div class="sub-case-header" @click="openSection = !openSection"> -->
        <h3>INFORMACIÓN PARA RECLAMOS DE CALIDAD DE PRODUCTO</h3>
        <!-- <div style="padding: 10px;">
          <span v-if="!openSection" class="material-symbols-outlined">expand_more</span>
          <span v-else class="material-symbols-outlined">expand_less</span>
        </div> -->
      </div>
      <div class="sub-case-list" v-show="openSection">
        <p>
          Para reclamos relacionados con la calidad del producto, sírvase completar la siguiente información la cual podrá
          encontrar en la parte lateral o trasera de cada envase. Así mismo, conservar el producto durante el tiempo que
          el reclamo esté vigente.
        </p>
        <icb-input id="haveproduct" label="Tiene el producto?" type="select" :options="productChoice"
          @emitvalue="setValue" :clean="clean" />
        <icb-input id="claimedproduct" label="Producto reclamado" @emitvalue="setValue" :clean="clean" />
        <icb-input id="containerinfo" label="Información disponible en el reverso del envase" :placeholder="'ej:\n01/01/2023\nISH18.R 238\n20:25'" type="textarea" @emitvalue="setValue"
        :clean="clean" />
        <!-- <icb-input id="date" label="Fecha de vencimiento" type="date" @emitvalue="setValue" :clean="clean" />
        <icb-input id="packer" label="Envasadora" @emitvalue="setValue" :clean="clean" />
        <icb-input id="juliano" label="Juliano" @emitvalue="setValue" :clean="clean" />
        <icb-input id="time" label="Hora" placeholder="HH:mm" validation="time" @emitvalue="setValue" :clean="clean" />
        <icb-input id="operator" label="Iniciales operador" @emitvalue="setValue" :clean="clean" /> -->
      </div>
    </div>
    <p>Archivos asociados</p>
    <div class="file-div">
      <input id="file" type="file" multiple
        accept=".rar,.zip,.ppt,.pptx,.pdf,.doc,.docx,.xls,.xlsx,.txt,.mp4,.jpg,.jpeg,.png,.gif,.svg,.csv,.vsdx,.vsd,.xlsm,.msg,.wav,.ovpn,.eml"
        @change="event => chargeFile(event)">
      <span>El tamaño máximo por archivo es de 5MB</span>
    </div>
    <div class="button-div">
      <icb-button label="ENVIAR" :disabled="!enabledButton" @buttonclick="sendForm" />
    </div>
  </div>
</template>

<script>
import { fetchService } from '@/services/fetchService'
import regionInfo from '@/services/region-commune-list.json'
import IcbInput from './IcbInput.vue'
import IcbButton from './IcbButton.vue'

export default {
  name: 'IcbForm',
  components: {
    IcbInput,
    IcbButton
  },
  data() {
    return {
      products: [
        {
          nombre: 'Consultas',
          id: 24
        },
        {
          nombre: 'Felicitaciones / Sugerencias',
          id: 25
        },
        {
          nombre: 'FoodService',
          id: 26
        },
        {
          nombre: 'Servicio al consumidor / Reclamos',
          id: 27
        },
        {
          nombre: 'Ventas',
          id: 28
        },
        {
          nombre: 'Transporte / Despacho',
          id: 29
        },
        {
          nombre: 'Otros',
          id: 30
        },
      ],
      // types: [],
      // subtypes: [],
      productChoice: ["Sí", "No"],
      data: {},
      files: [],
      regionList: regionInfo.region,
      communeList: regionInfo.commune,
      openSection: true,
      notification: '',
      notificationError: false,
      loadingFiles: false,
      sendingForm: false,
      clean: false
    }
  },
  computed: {
    enabledButton() {
      return !!this.data?.rut && !!this.data?.name && !!this.data?.lastname && !!this.data?.email && !!this.data?.phone && !!this.data?.region &&
        !!this.data?.commune && !!this.data?.address && !!this.data?.product && !!this.data?.description
        && !this.loadingFiles
      // return !!this.data?.rut && !!this.data?.name && !!this.data?.lastname && !!this.data?.email && !!this.data?.phone && !!this.data?.region &&
      //   !!this.data?.commune && !!this.data?.address && !!this.data?.product && !!this.data?.type && !!this.data?.subtype && !!this.data?.description
      //   && !this.loadingFiles
    }
  },
  methods: {
    // async getProducts() {
    //   this.products = await fetchService({
    //     endpoint: '/productos/get',
    //     apitype: 'ask'
    //   })
    // },
    // async getTypes(id) {
    //   this.types = await fetchService({
    //     endpoint: `/tipos/getby?idproducto=${id}`,
    //     apitype: 'ask'
    //   })
    // },
    // async getSubtypes(id) {
    //   this.subtypes = await fetchService({
    //     endpoint: `/subtipos/getby?idproducto=${this.data.product}&idtipo=${id}`,
    //     apitype: 'ask'
    //   })
    // },
    async getContact() {
      const payload = {
        metodo: "ValidarContactoIdentificadorEmail",
        identificador: this.data.rut,
        email: this.data.email,
        instancia: this.$store.state.user.company
      }
      const response = await fetchService({
        apitype: 'lambda',
        method: 'POST',
        payload
      })
      return response
    },
    async createContact() {
      const payload = {
        metodo: "CrearContacto",
        nombre: this.data.name,
        apellido: this.data.lastname,
        identificador: this.data.rut,
        telefono: this.data.phone,
        direccion: this.data.address,
        email: this.data.email,
        timezone: "America/Santiago",
        instancia: this.$store.state.user.company,
        cf: {
          contacto_region: this.data.region,
          contacto_comuna: this.data.commune
        }
      }
      const response = await fetchService({
        apitype: 'lambda',
        method: 'POST',
        payload
      })
      return response
    },
    async createCase() {
      const payload = {
        metodo: "CreacionCasoBotPUC",
        identificador: this.data.rut,
        email: this.data.email,
        origen: 'FormWeb',
        asunto: this.data.subject,
        producto: this.data.productname,
        motivo: 'Por definir',
        submotivo: 'Por definir',
        cf: {
          servicio_tiene_producto: this.data.haveproduct,
          servicio_producto_reclamado: this.data.claimedproduct,
          servicio_fvenc_producto: this.data.date,
          servicio_envasadora_producto: this.data.packer,
          servicio_juliano_producto: this.data.juliano,
          servicio_hora_producto: this.data.time,
          servicio_ini_ope_producto: this.data.operator,
          logistica_numero_pedido: this.data.order
        },
        instancia: this.$store.state.user.company
      }
      const response = await fetchService({
        apitype: 'lambda',
        method: 'POST',
        payload
      })
      return response
    },
    async addNote(refnum, noteType) {
      const payload = {
        metodo: "AgregarNotaCaso",
        refnum,
        identificador: this.data.rut,
        email: this.data.email,
        nota: noteType === 'description' ? this.data.description : this.data.containerinfo,
        instancia: this.$store.state.user.company
      }
      const response = await fetchService({
        apitype: 'lambda',
        method: 'POST',
        payload
      })
      return response
    },
    chargeFile(event) {
      this.loadingFiles = true
      this.files = []
      const arrayFiles = event.target.files
      const reader = new FileReader()
      let payload
      for (const file of arrayFiles) {
        if (file.size > 5242880) continue
        let content
        reader.readAsDataURL(file)
        reader.onload = (event) => {
          content = event.target.result
          content = content.split(',')
          content = content[1]
          payload = {
            type: file.type,
            content,
            name: file.name,
          }
          this.files.push(payload)
        }
      }
      this.loadingFiles = false
    },
    async uploadFiles(idCaso) {
      if (!this.files.length) return false
      const instancia = this.$store.state.user.company
      let amountFilesOk = 0
      for (const file of this.files) {
        const payload = {
          metodo: "AdjuntarArchivoBin",
          mimetype: file.type,
          idCaso,
          contenido: file.content,
          nombre: file.name,
          instancia
        }
        const response = await fetchService({
          apitype: 'lambda',
          method: 'POST',
          payload
        })
        if (response.mensaje === 'OK') amountFilesOk++
      }
      if (this.files.length === amountFilesOk) return { mensaje: 'OK' }
      else return { mensaje: 'NO_OK' }
    },
    async sendForm() {
      this.sendingForm = true
      const getContact = await this.getContact()
      if (getContact.mensaje === "NO_OK") {
        if (getContact.detalle.includes('Contacto invalido')) {
          this.sendingForm = false
          this.notification = 'Datos del contacto inválidos.'
          this.notificationError = true
          return
        } 
        const contactData = await this.createContact()
        if (contactData.mensaje === 'NO_OK') {
          this.sendingForm = false
          this.notification = 'No se pudo crear el contacto, intente nuevamente.'
          this.notificationError = true
          return
        }
      }
      const postCase = await this.createCase()
      if (postCase.mensaje === "NO_OK") {
        this.sendingForm = false
        this.notification = 'No se pudo crear el caso, intente nuevamente.'
        this.notificationError = true
        return
      }
      const response = await JSON.parse(postCase.data)
      const descriptionNote = await this.addNote(response.numref, 'description')
      if (descriptionNote.mensaje === 'NO_OK') {
        this.notification = 'No se pudo agregar la descripción al caso.'
        this.notificationError = true
      }
      if (this.data.containerinfo) {
        const containerNote = await this.addNote(response.numref, 'container')
        if (containerNote.mensaje === 'NO_OK') {
          this.notification = 'No se pudo agregar la información del envase al caso.'
          this.notificationError = true
        }        
      }
      const files = await this.uploadFiles(response.id)
      if (files.mensaje === 'NO_OK') {
        this.notification += '\nNo se pudieron agregar todos los archivos al caso.'
        this.notificationError = true
      }
      this.sendingForm = false
      this.notification += '\nEl caso fue creado correctamente.'
      this.notificationError = false
      this.clean = true
      setTimeout(() => this.clean = false, 1000)
    },
    setValue(event) {
      this.data = { ...this.data }
      if (event.id === 'product' || event.id === 'type' || event.id === 'subtype') {
        this.data[`${event.id}name`] = event.name
      }
      this.data[event.id] = event.value
      if (event.id === 'rut') this.data.rut = event.value.replaceAll('.','')
    },
    closeModal() {
      this.notification = ''
      this.notificationError = false
    }
  },
  watch: {
    'data.product'(value) {
      // if (value) this.getTypes(value)
      // else {
      //   this.types = []
      // }
      // this.data.type = ''
      if (value !== 27) {
        delete this.data.claimedproduct
        delete this.data.containerinfo
        delete this.data.haveproduct
        // this.openSection = false
      }
      if (value !== 29) {
        delete this.data.order
      }
    },
    // 'data.type'(value) {
    //   if (value) this.getSubtypes(value)
    //   else {
    //     this.subtypes = []
    //   }
    //   this.data.subtype = ''
    // }
  },
  mounted() {
    // this.getProducts()
  }
}
</script>

<style scoped>
@media (max-width: 460px) {
  .form-div {
    width: 250px;
  }
  .sub-case-div,
  .file-div {
    width: 245px;
  }
  input[type="file"] {
    width: 230px;
  }
}

@media (min-width: 461px) and (max-width: 874px) {
  .form-div {
    width: 413px;
  }

  .sub-case-div,
  .file-div {
    width: 410px;
  }
}

@media (min-width: 875px) and (max-width: 1267px) {
  .form-div {
    width: 825px;
  }

  .sub-case-div,
  .file-div {
    width: 821px;
  }
}

@media (min-width: 1268px) {
  .form-div {
    width: 1235px;
  }

  .sub-case-div,
  .file-div {
    width: 1230px;
  }
}

.form-div {
  border: 1px solid grey;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-div {
  display: flex;
  flex-wrap: wrap;
}

.case-div {
  display: flex;
  flex-wrap: wrap;
}

.sub-case-div {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #e41319;
  border-radius: 5px;
  margin-top: 5px;
  padding-bottom: 5px;
}

.sub-case-list {
  display: flex;
  flex-wrap: wrap;
}

.sub-case-header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
}

.file-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #e41319;
  color: #fff;
  min-height: 100px;
  margin-bottom: 10px;
}

.file-div input,
.file-div input::file-selector-button {
  cursor: pointer;
  font-family: Montserrat;
  height: 50px;
}

.button-div {
  padding: 5px;
}

.notification-div {
  position: fixed;
  top: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 380px;
  width: 380px;
  background: white;
  border: 1px solid red;
  border-radius: 5px;
  padding: 10px;
  z-index: 10;
}

.notification-blur {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 5;
  backdrop-filter: blur(2px);
}

.notification-icon-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40%;
}

.error-icon {
  color: red;
  font-size: 100px;
}

.check-icon {
  font-size: 100px;
  color: green;
}

.load-icon {
  font-size: 200px;
  animation: spin 2s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.notification-message-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60%;
}

.notification-message {
  font-size: 25px;
  white-space: pre-wrap;
}

.close-modal {
  display: flex;
  width: 100%;
  justify-content: end;
}
</style>