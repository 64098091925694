import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      user: 'anonymous',
      company: 'icb'
    },
    api: {
      ask: 'https://api.beaware360.com/ba360/apir/v11/cp/ask',
      lambda: 'https://apiserv.beaware360.com/GestionBA360'
    }
  }
})
