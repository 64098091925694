<template>
  <button :disabled="disabled" @click="emitClick()">{{ label }}</button>
</template>

<script>
export default {
  name: 'IcbButton',
  props: {
    label: String,
    disabled: Boolean
  },
  methods: {
    emitClick() {
      this.$emit('buttonclick')
    }
  }
}
</script>

<style scoped>
button {
  text-decoration: none;
  border: none;
  border-radius: 5px;
  background: #e41319;
  color: white;
  padding: 10px;
  cursor: pointer;
}
button:disabled {
  background: #838383;
}
</style>